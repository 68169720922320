import React, { Component } from 'react';

class NCCourt extends Component {
    state = {
        items: [{
            "OfficerName": "",
            "CourtDate": "11/09/2022",
            "County": "WAKE",
            "CourtSession": "NC",
            "CourtRoom": "0101",
            "CaseNumber": "0_CR",
            "Defendant": "doe,john",
            "CitationNumber": "0"
        }]
    }

    componentDidMount() {
        console.log("Mounted")
        fetch('/data/nc_court_records.json')
        .then(res => res.json())
        .then((data) => {
            this.setState({ items: data.data })
    })
    .catch(console.log)
    }

    render() {
        return (
    <div className="rss-list-container">
        <table border={1}>
            <thead>
                <tr>
                    <th>Case</th>
                    <th>Citation</th>
                    <th>Defendant</th>
                    <th>Office Name</th>
                    <th>CourtDate</th>
                    <th>County</th>
                    <th>Session</th>
                    <th>Room</th>
                </tr>
            </thead>
            {Object.entries(this.state.items).map(([key, value]) => (
                <tr key={key}>
                    <td>{value.CaseNumber}</td>
                    <td>{value.CitationNumber}</td>
                    <td>{value.Defendant}</td>
                    <td>{value.OfficerName}</td>
                    <td>{value.CourtDate}</td>
                    <td>{value.County}</td>
                    <td>{value.CourtSession}</td>
                    <td>{value.CourtRoom}</td>
                </tr>
            ))}
        </table>
        <ul>

        </ul>
    </div>
  )
    }

}

export default NCCourt




